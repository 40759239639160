import PropTypes from "prop-types";
import { ReactNode } from "react";
import styled, { createGlobalStyle } from "styled-components";

const StyledScreenReaderText = styled.span`
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
`;

const GlobalStyle = createGlobalStyle`
  body:hover .screenReader a {
    display: none;
  }
  body:hover .screenReader input {
    display: none;
  }
  body:hover .screenReader button {
    display: none;
  }
`;

interface AccessibleTextBaseProps {
  className?: string;
}

interface ScreenReaderTextProps {
  screenReaderText: ReactNode;
}

interface VisibleTextProps {
  visualText: ReactNode;
}

export type AccessibleTextProps =
  | (AccessibleTextBaseProps & ScreenReaderTextProps)
  | (AccessibleTextBaseProps & VisibleTextProps);

function AccessibleText({ className = "", ...props }: AccessibleTextProps) {
  const srText =
    "screenReaderText" in props ? props?.screenReaderText : props.visualText;
  const visualText = "visualText" in props ? props?.visualText : "";

  return (
    <>
      <StyledScreenReaderText className="screenReader">
        {srText}
      </StyledScreenReaderText>
      {visualText && (
        <span aria-hidden={true} className={className}>
          {visualText}
        </span>
      )}
      <GlobalStyle />
    </>
  );
}

export default AccessibleText;
