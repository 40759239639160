import React, { ReactNode, useState } from "react";
import styled, { css } from "styled-components";
import { FieldProps } from "formik";
import {
  colors,
  Icon,
  Image,
  IconName,
  Theme,
  ImageProps,
  media,
} from "@literati/public-ui-shared-react";
import LegacyIcon from "@/atoms/Icon";
import { Icon as DLSIcon } from "@literati/public-ui-shared-react";

import { PlanSelectionModal } from "components/PlanSelection/PlanInfoModal";
import { Plans } from "components/PlanSelection/planSelectionTypes";
import { CheckoutSession } from "lib/api/checkout";

/** This is the className that will be pre-pended to all of PlanSelectionRadioClassName's inner component classNames */
export const PLAN_SELECTION_RADIO_CLASS_BASE = "plan-selection-radio";

/** These are all of the internal classNames that are used within the PlanSelectionRadio component */
export const PlanSelectionRadioClassName = {
  BASE: PLAN_SELECTION_RADIO_CLASS_BASE,
  TOP_CONTENT: `${PLAN_SELECTION_RADIO_CLASS_BASE}-top-content`,
  INPUT: `${PLAN_SELECTION_RADIO_CLASS_BASE}-input`,
  FOCUS_INDICATOR: `${PLAN_SELECTION_RADIO_CLASS_BASE}-focus-indicator`,
  CHECKMARK: `${PLAN_SELECTION_RADIO_CLASS_BASE}-checkmark`,
  CHECKMARK_ICON: `${PLAN_SELECTION_RADIO_CLASS_BASE}-checkmark-icon`,
  IMAGE: `${PLAN_SELECTION_RADIO_CLASS_BASE}-image`,
  PLAN_NAME: `${PLAN_SELECTION_RADIO_CLASS_BASE}-plan-name`,
  PRICE: `${PLAN_SELECTION_RADIO_CLASS_BASE}-price`,
  MONTHLY_PRICE: `${PLAN_SELECTION_RADIO_CLASS_BASE}-monthly-price`,
  MONTHLY_PRICE_DESCRIPTION: `${PLAN_SELECTION_RADIO_CLASS_BASE}-monthly-description`,
  SAVINGS: `${PLAN_SELECTION_RADIO_CLASS_BASE}-savings`,
  NOTE: `${PLAN_SELECTION_RADIO_CLASS_BASE}-note`,
  NOTE_ICON: `${PLAN_SELECTION_RADIO_CLASS_BASE}-note-icon`,
  NOTE_TEXT: `${PLAN_SELECTION_RADIO_CLASS_BASE}-note-text`,
} as const;

export type PlanSelectionRadioVariant = "monthly" | "annual";

export interface PlanSelectionRadioProps extends FieldProps {
  className?: string;
  checked: boolean;
  testId?: string;
  imageProps?: ImageProps;
  inputValue: PlanSelectionRadioVariant;
  monthlyPrice: string;
  note?: string;
  noteIcon: IconName;
  plans?: Plans | null;
  planName?: string;
  variant?: PlanSelectionRadioVariant;
  checkoutSession: CheckoutSession;
}

const baseContainerStyle = css<{ $checked: boolean }>`
  display: block;
  position: relative;
  border-radius: 12px;
  border: ${({ $checked }) =>
    `1px solid ${$checked ? colors.periwinkleShade1 : colors.newsprint3}`};
  box-shadow: ${({ $checked }) =>
    $checked && "0px 2px 10px rgba(0, 0, 0, 0.2)"};

  ${media.hover} {
    &:hover {
      border: ${({ $checked }) =>
        `1px solid ${!$checked && colors.periwinkleShade1}`};
      box-shadow: ${({ $checked }) =>
        !$checked && "0px 2px 10px rgba(0, 0, 0, 0.2)"};

      .${PlanSelectionRadioClassName.CHECKMARK} {
        border: ${({ $checked }) =>
          !$checked && `1px solid ${colors.periwinkleShade1}`};
      }
    }
  }
`;

const BaseContainer = styled.div<{ $checked: boolean }>`
  ${baseContainerStyle};
`;
const Label = styled.label<{ $checked: boolean }>`
  cursor: pointer;
  ${baseContainerStyle};
`;

export const FocusIndicatorDiv = styled.div`
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 0.5rem);
  height: calc(100% + 0.5rem);
  transform: translate(-50%, -50%);
  border: 2px solid ${({ theme }) => theme.colors.transparent};
  transition: width 0.1s, border 0.1s;
  border-radius: 1rem;
`;

export const focusIndicatorStylesFocused = css`
  width: calc(100% + 1rem);
  height: calc(100% + 1rem);
  border: 2px solid ${({ theme }) => theme.colors.tealShade2};
`;

export const checkboxInputFocusStyles = css`
  ${media.hover} {
    &:focus + ${FocusIndicatorDiv} {
      ${focusIndicatorStylesFocused}
    }
  }
`;

const TopContent = styled.div`
  padding: 1rem 1.25rem;
  padding-top: 0;
`;

const Input = styled.input`
  appearance: none;
  background-appearance: clear;
  margin: 0;

  ${checkboxInputFocusStyles}
`;

const Checkmark = styled.div<{ $checked: boolean }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1rem;
  right: 1.25rem;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 50%;
  border: ${({ $checked }) =>
    `1px solid ${$checked ? colors.transparent : colors.newsprint3}`};
  background: ${({ $checked }) =>
    $checked ? colors.periwinkleShade1 : colors.transparent};
`;

const PlanName = styled.p`
  margin: 0.5rem 0 0;
  font-size: 1.125rem;
  font-weight: 700;
  color: ${colors.inkBlack};
`;

const MonthlyPrice = styled.span`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${colors.periwinkleShade1};
`;

const MonthlyPriceDescription = styled.span`
  font-size: 0.875rem;
  font-weight: 500;
`;

const CreditInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.5rem 0;
  gap: 0.5rem;
  font-size: 0.8125rem;
  color: ${colors.tealShade3};
  background-color: ${colors.mintTint4};
  border-radius: 4px;
`;

const IconContainer = styled.div`
  margin-left: auto;
`;

const InfoIcon = styled(DLSIcon)`
  height: 1.125rem;
  width: 1.125rem;
  cursor: pointer;
`;

const Note = styled.div<{ $checked: boolean }>`
  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  height: 50px;
  border-radius: 0 0 12px 12px;
  background: ${({ $checked }) =>
    $checked ? colors.papierBlanc : colors.papierBlanc};
`;

const NoteIcon = styled(Icon)<{ $checked: boolean }>`
  path {
    fill: ${({ $checked }) =>
      $checked ? colors.periwinkleShade1 : colors.warmGray1};
  }
`;

const NoteText = styled.p`
  margin: 0 0 0 0.5rem;
  padding: 0.25rem 0 0;
  font-size: 0.8125rem;
  color: ${colors.inkBlack};
`;

const TextGray = styled.p`
  font-style: italic;
  color: ${colors.warmGray3};
`;

const ANNUAL_PLAN_NOTE = "Cancel anytime within the first 30 days!";
const MONTHLY_PLAN_NOTE = "Cancel or change anytime.";

export interface CreditInfoBannerProps
  extends React.ComponentProps<typeof CreditInfo> {
  monthlyPrice: string;
  showGiftIcon?: boolean;
}

export const CreditInfoBanner = ({
  children = (
    <>
      <strong>Get $60 credit</strong> to spend on books
    </>
  ),
  monthlyPrice,
  showGiftIcon = false,
  ...props
}: CreditInfoBannerProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const onClose = () => setModalOpen(false);
  const onInfoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setModalOpen(true);
  };

  return (
    <>
      <CreditInfo {...props} onClick={onInfoClick}>
        {showGiftIcon ? (
          <LegacyIcon name={"gift"} size="1rem" fill={colors.tealShade3} />
        ) : null}
        <p>{children}</p>
        <IconContainer>
          <InfoIcon name={IconName.INFO} fill="tealShade3" />
        </IconContainer>
      </CreditInfo>
      <PlanSelectionModal
        isOpen={isModalOpen}
        monthlyPrice={monthlyPrice}
        onClose={onClose}
      />
    </>
  );
};

export const PlanSelectionContainer = ({
  className = "",
  isForm = false,
  checked,
  note,
  noteIcon,
  children,
}: {
  className?: string;
  checked: boolean;
  isForm?: boolean;
  note: string;
  noteIcon: IconName;
  children: ReactNode;
}) => {
  const cls = PlanSelectionRadioClassName;
  const Container = isForm ? Label : (BaseContainer as React.ElementType);

  if (ANNUAL_PLAN_NOTE === note) {
    noteIcon = IconName.WATCH;
  }

  return (
    <Theme>
      <Container $checked={checked} className={`${cls.BASE} ${className}`}>
        <TopContent className={cls.TOP_CONTENT}>{children}</TopContent>
        <Note $checked={checked} className={cls.NOTE}>
          <NoteIcon
            $checked={checked}
            className={cls.NOTE_ICON}
            name={noteIcon}
          />
          <NoteText
            className={cls.NOTE_TEXT}
            dangerouslySetInnerHTML={{ __html: note }}
          ></NoteText>
        </Note>
      </Container>
    </Theme>
  );
};

export const PlanSelectionRadio = ({
  className = "",
  checked,
  imageProps,
  inputValue,
  monthlyPrice,
  note,
  noteIcon,
  planName,
  plans,
  testId,
  variant = "annual",
  checkoutSession,
  ...props
}: PlanSelectionRadioProps) => {
  const cls = PlanSelectionRadioClassName;

  const isAnnual = variant === "annual";
  const annualPrice = plans?.annual?.amount;

  const _planName = planName
    ? planName
    : isAnnual
    ? "Yearly Membership"
    : "Monthly Membership";

  const _note = note ? note : isAnnual ? ANNUAL_PLAN_NOTE : MONTHLY_PLAN_NOTE;

  return (
    <PlanSelectionContainer
      {...{ checked, className, note: _note, noteIcon, isForm: true }}
    >
      <Input
        className={cls.INPUT}
        data-testid={testId}
        id={variant}
        type="radio"
        {...props.field}
        value={inputValue}
      />
      <Checkmark $checked={checked} className={cls.CHECKMARK}>
        {checked && (
          <Icon
            className={cls.CHECKMARK_ICON}
            fill="white"
            name={IconName.CHECKMARK}
          />
        )}
      </Checkmark>
      {isAnnual && imageProps && imageProps?.src && (
        <Image alt="book box" className={cls.IMAGE} {...imageProps} contain />
      )}
      <PlanName className={cls.PLAN_NAME}>{_planName}</PlanName>
      <div className={cls.PRICE}>
        <MonthlyPrice className={cls.MONTHLY_PRICE}>
          {monthlyPrice}
        </MonthlyPrice>
        <MonthlyPriceDescription className={cls.MONTHLY_PRICE_DESCRIPTION}>
          {" "}
          per month
          {isAnnual && annualPrice ? (
            <TextGray>paid yearly - cart price {annualPrice}</TextGray>
          ) : null}
        </MonthlyPriceDescription>
      </div>
      {isAnnual && (
        <CreditInfoBanner
          checkoutSession={checkoutSession}
          monthlyPrice={monthlyPrice}
          showGiftIcon
        />
      )}
    </PlanSelectionContainer>
  );
};

export default PlanSelectionRadio;
